import React from 'react'
import { Table } from 'antd'
import numeral from 'numeral'
import styles from './ProjectPhaseTimeFrameTable.css'

const mon = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0')}`
const rat = value => `${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0')}%`

type Props = {
  data: [],
  fetching: Boolean,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      data,
      fetching,
    } = this.props

    const columns = [
      {
        title: 'Project Phase',
        key: 'title',
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {record.title}
            </div>
          </div>
        ),
      },
      {
        title: 'Value',
        key: 'value',
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 16 }}>
              <strong>
                {mon(record.value_remaining_total)}
              </strong>
              {` ${rat(record.value_remaining_total_ratio)}`}
            </div>
            <div>&nbsp;</div>
            <div>
              Overdue:
              <strong>
                {` ${mon(record.value_remaining_overdue)}`}
              </strong>
              {` ${rat(record.value_remaining_overdue_ratio)}`}
            </div>
            <div>
              Upcoming:
              <strong>
                {` ${mon(record.value_remaining_upcoming)}`}
              </strong>
              {` ${rat(record.value_remaining_upcoming_ratio)}`}
            </div>
            <div>
              Pending:
              <strong>
                {` ${mon(record.value_remaining_pending)}`}
              </strong>
              {` ${rat(record.value_remaining_pending_ratio)}`}
            </div>
          </div>
        )
      },
      {
        title: '',
        key: 'spacer',
        width: 400,
      },
    ]
    
    return (

      <Table
        columns={columns}
        dataSource={data}
        rowKey='key'
        loading={fetching}
      />

    )

  }

}
