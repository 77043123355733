import React from 'react'
import { Table } from 'antd'
import numeral from 'numeral'
import styles from './ProjectPhaseContractsManagerIdTable.css'

const mon = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0')}`
const rat = value => `${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0')}%`

type Props = {
  data: [],
  fetching: Boolean,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      data,
      fetching,
    } = this.props

    const columns = [
      {
        title: 'Contracts Manager',
        key: 'title',
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {record.title}
            </div>
          </div>
        ),
      },
      {
        title: 'Quantity',
        key: 'quantity',
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            {record.project_phases.map(projectPhase => (
              <div key={projectPhase.key}>
                {projectPhase.title}:
                <strong>
                  {` ${numeral(projectPhase.quantity).format('0,0')}`}
                </strong>
              </div>
            ))}
          </div>
        )
      },
      {
        title: 'Remaining',
        key: 'value_remaining',
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            {record.project_phases.map(projectPhase => (
              <div key={projectPhase.key}>
                {projectPhase.title}:
                <strong>
                  {` ${mon(projectPhase.value_remaining)}`}
                </strong>
              </div>
            ))}
          </div>
        )
      },
    ]
    
    return (

      <Table
        columns={columns}
        dataSource={data}
        rowKey='key'
        loading={fetching}
      />

    )

  }

}
