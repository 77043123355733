exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".XE3wveL_qt938oc-FuQD5 {\n  text-align: right !important;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Insights/Components/ProjectPhaseContractsManagerIdTable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;CAC9B","file":"ProjectPhaseContractsManagerIdTable.css","sourcesContent":[".numericColumn {\n  text-align: right !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"numericColumn": "XE3wveL_qt938oc-FuQD5"
};