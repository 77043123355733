exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._11c1selIljMV-wCV778N8g {\n  text-align: right !important;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Insights/Components/ProjectPhaseTimeFrameTable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;CAC9B","file":"ProjectPhaseTimeFrameTable.css","sourcesContent":[".numericColumn {\n  text-align: right !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"numericColumn": "_11c1selIljMV-wCV778N8g"
};